import { useMemo } from 'react';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import BaseRendimiento from '../../servicios/Rendimiento/BaseRendimiento';

export default function Rendimiento() {
    const { data: presupuestos } = useEditFormData('presupuestos');

    const rendimiento = useMemo(() => {
        const rendimiento = {
            total_comercial: 0,
            total_facturado: 0,
        };

        presupuestos
            .filter((presupuesto) => presupuesto.estado === 'ACEPTADO')
            .forEach((presupuesto) => {
                rendimiento.total_facturado += presupuesto.importe_venta;
            });

        return rendimiento;
    }, [presupuestos]);

    return <BaseRendimiento rendimiento={rendimiento} />;
}
