import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import { dataProvider } from '../../api/companies';
import { formatDate, formatDateTime } from '../../utils';
import Button from '../common/Button';
import EditFormBase, { withEditForm } from '../common/forms/EditForm';
import InlineDialog from '../common/forms/InlineDialog';
import { FormTab, TabbedForm } from '../common/forms/TabbedForm';
import FeatureFlagsForm from './FeatureFlagsForm';
import { UploadClientesFileForm } from './UploadClientesFileForm';
import { UploadOperariosFileForm } from './UploadOperariosFileForm';

const useStyles = makeStyles(
    (theme) => ({
        step: {
            display: 'flex',
            alignItems: 'center',
            '& .MuiCheckbox-root': {
                padding: 2,
            },
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'EditCompanyForm' },
);

const CompaniesSchema = Yup.object().shape({
    name: Yup.string().required('Requerido'),
});

const TabGeneral = ({ classes }) => (
    <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
            <TextField name='name' label='Nombre' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <TextField name='nif' label='NIF' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='telefono' label='Telefono' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <TextField name='direccion' label='Direccion' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='codigo_postal' label='Codigo postal' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <TextField name='poblacion' label='Poblacion' fullWidth />
        </Grid>
        <Grid item xs={6}>
            <TextField name='provincia' label='Provincia' fullWidth />
        </Grid>

        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='firma_rgpd' Label={{ label: 'Firma RGPD' }} />
        </Grid>
        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='autoriza_pago' Label={{ label: 'Autoriza el pago' }} />
        </Grid>
        <Grid item xs={3}>
            <CheckboxWithLabel type='checkbox' name='activo' Label={{ label: 'Activo' }} />
        </Grid>
        <Grid item xs={3}>
            <KeyboardDatePicker name='expiration_date' label='Fecha de baja' format={'dd/MM/yyyy'} fullWidth autoOk />
        </Grid>

        <Grid item xs={6}>
            <CheckboxWithLabel type='checkbox' name='es_demo' Label={{ label: 'Cuenta de DEMO' }} />
        </Grid>
        <Grid item xs={6}>
            <TextField name='dias_prueba' label='Dias de prueba' type='number' fullWidth />
        </Grid>

        <Grid item xs={6}>
            <CheckboxWithLabel type='checkbox' name='es_kit_digital' Label={{ label: 'Tiene Kit Digital' }} />
        </Grid>
        <Grid item xs={6}>
            <KeyboardDatePicker
                name='fecha_firma_kit_digital'
                label='Fecha de firma Kit Digital'
                format={'dd/MM/yyyy'}
                fullWidth
                autoOk
            />
        </Grid>
    </Grid>
);

const EditForm = withEditForm('companies', CompaniesSchema)(EditFormBase);

export default function EditCompanyForm({ basePath, match, onUnauthorized, onError }) {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const history = useHistory();
    const { id } = useParams();

    function queryData() {
        setData(null);

        dataProvider
            .getById(id)
            .then((newData) => {
                setData({
                    ...newData,
                    fecha_firma_kit_digital: newData.fecha_firma_kit_digital
                        ? new Date(newData.fecha_firma_kit_digital)
                        : null,
                });
            })
            .catch((error) => {
                if (error.status === 404) {
                    history.push(basePath);
                } else {
                    onError(error);
                }
            });
    }

    useEffect(() => {
        queryData();
    }, [id]);

    return (
        data && (
            <>
                <EditForm
                    title={`Compañia: ${data.name}`}
                    data={data}
                    data_id={id}
                    basePath={basePath}
                    onSave={() => queryData()}
                    deleteTitle='Borrar compañia'
                    deleteText='¿Estás seguro de que deseas borrar esta compañia permanentemente? Esta acción no se puede deshacer.'
                    dataProvider={dataProvider}
                    onUnauthorized={onUnauthorized}
                >
                    <TabbedForm match={match}>
                        <FormTab label='General'>
                            <TabGeneral />
                        </FormTab>
                    </TabbedForm>
                </EditForm>
                <Grid container spacing={2} style={{ marginTop: 24 }}>
                    <Grid item xs={4} className={classes.column}>
                        <InlineDialog title='Datos de facturación'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {data.name}
                                </Grid>
                                <Grid item xs={12}>
                                    {data.direccion}
                                </Grid>
                                <Grid item xs={12}>
                                    {data.codigo_postal} {data.poblacion}, {data.provincia}
                                </Grid>
                                <Grid item xs={12}>
                                    NIF: {data.nif}
                                </Grid>
                            </Grid>
                        </InlineDialog>

                        <InlineDialog title='Datos de acceso'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    Email: {data.user.email}
                                </Grid>
                                <Grid item xs={12}>
                                    Nombre de usuario: {data.user.username}
                                </Grid>
                            </Grid>
                        </InlineDialog>

                        <InlineDialog title='Importar clientes'>
                            <UploadClientesFileForm companyId={id} />
                        </InlineDialog>
                        <InlineDialog title='Importar operarios'>
                            <UploadOperariosFileForm companyId={id} />
                        </InlineDialog>
                    </Grid>
                    <Grid item xs={4} className={classes.column}>
                        <InlineDialog
                            title='Primeros pasos'
                            titleActions={
                                !data.primeros_pasos || !data.primeros_pasos.activo ? (
                                    <Button
                                        color='dark'
                                        onClick={() => {
                                            companiesProvider.enablePrimerosPasos(id).then(queryData);
                                        }}
                                    >
                                        Activar
                                    </Button>
                                ) : (
                                    <Button
                                        color='dark'
                                        onClick={() => {
                                            companiesProvider.disablePrimerosPasos(id).then(queryData);
                                        }}
                                    >
                                        Desactivar
                                    </Button>
                                )
                            }
                        >
                            <Grid container spacing={1}>
                                {data.primeros_pasos ? (
                                    <>
                                        <Grid item xs={12} className={classes.step}>
                                            <Typography variant='subtitle1'>
                                                <strong>Estado: </strong>
                                                {data.primeros_pasos.activo ? 'Activo' : 'Inactivo'}
                                            </Typography>
                                        </Grid>
                                        {data.primeros_pasos.steps.map((step) => (
                                            <Grid item xs={12} key={step.id} className={classes.step}>
                                                <Tooltip
                                                    title={
                                                        step.fecha_completado
                                                            ? `Completado el ${formatDateTime(step.fecha_completado)}`
                                                            : 'Pendiente'
                                                    }
                                                >
                                                    <Checkbox size='small' checked={Boolean(step.fecha_completado)} />
                                                </Tooltip>
                                                <Typography variant='body2'>{step.titulo}</Typography>
                                            </Grid>
                                        ))}
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        No tiene primeros pasos
                                    </Grid>
                                )}
                            </Grid>
                        </InlineDialog>
                        <InlineDialog title='Feature flags'>
                            <FeatureFlagsForm companyId={id} />
                        </InlineDialog>
                    </Grid>
                    <Grid item xs={4} className={classes.column}>
                        <InlineDialog title='Datos de pago'>
                            <Grid container spacing={1}>
                                {data.datos_pago ? (
                                    <>
                                        <Grid item xs={12}>
                                            {data.datos_pago.nombre}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {data.datos_pago.numero_cuenta}
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        No tiene datos de pago o tarifa
                                    </Grid>
                                )}
                            </Grid>
                        </InlineDialog>
                        <InlineDialog title='Suscripción'>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    Estado: {data.estado}
                                </Grid>
                                {data.tarifa ? (
                                    <>
                                        <Grid item xs={12}>
                                            Fecha proximo pago: {formatDate(data.tarifa.fecha_proximo_pago)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Frecuencia: {data.tarifa.frecuencia_pago}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Codigo tarifa: {data.tarifa.codigo_tarifa}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Importe: {data.tarifa.importe.toFixed(2)} EUR
                                        </Grid>
                                        <Grid item xs={12}>
                                            Numero operarios (contratados / en uso): {data.tarifa.numero_licencias} /{' '}
                                            {data.tarifa.numero_operarios_activos}
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            No ha configurado la suscripción
                                        </Grid>
                                        <Grid item xs={12}>
                                            Fecha fin de prueba: {formatDate(data.fecha_fin_prueba)} (
                                            {data.dias_restantes_prueba} días restantes)
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </InlineDialog>
                        <InlineDialog title='Pagos'>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Importe</TableCell>
                                        <TableCell>Devuelto</TableCell>
                                        {/* <TableCell> */}
                                        {/*    Acciones */}
                                        {/* </TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.pagos.map((pago) => (
                                        <TableRow key={pago.id}>
                                            <TableCell>{formatDate(new Date(pago.fecha))}</TableCell>
                                            <TableCell>{pago.importe}</TableCell>
                                            <TableCell>{pago.devuelto ? 'Si' : 'No'}</TableCell>
                                            {/* <TableCell> */}
                                            {/*    <ButtonDialog */}
                                            {/*        button={ */}
                                            {/*            <IconButton style={{padding: 6}} aria-label="Borrar"> */}
                                            {/*                <DeleteIcon fontSize="small"/> */}
                                            {/*            </IconButton> */}
                                            {/*        } */}
                                            {/*        tooltip='Borrar' */}
                                            {/*        dialogTitle='Borrar pago' */}
                                            {/*        dialogText='Esta operación no se puede deshacer. ¿Seguro que deseas continuar?' */}
                                            {/*        onConfirm={() => { */}
                                            {/*            companiesProvider */}
                                            {/*                .actionOnId(id, `pagos/${pago.id}`, null, {method: 'DELETE'}) */}
                                            {/*                .then(queryData) */}
                                            {/*                .catch(err => { */}
                                            {/*                    console.log(err); */}
                                            {/*                    snackbar.showMessage(err.body.message); */}
                                            {/*                }); */}
                                            {/*        }} */}
                                            {/*    /> */}
                                            {/* </TableCell> */}
                                        </TableRow>
                                    ))}
                                    {data.pagos.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={3}>No hay pagos registrados</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </InlineDialog>
                    </Grid>
                </Grid>
            </>
        )
    );
}

EditCompanyForm.propTypes = {
    basePath: PropTypes.any,
    match: PropTypes.any,
    onError: PropTypes.any,
    onUnauthorized: PropTypes.any,
};

TabGeneral.propTypes = {
    classes: PropTypes.any,
};
