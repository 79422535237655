import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PropTypes from 'prop-types';
import { format } from '../../../../utils';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            borderTop: `1px solid ${theme.palette.neutral.grey1}`,
            '&:last-of-type': {
                borderBottom: `1px solid ${theme.palette.neutral.grey1}`,
            },
            '&>div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            },
        },
        tiempo: {
            fontSize: 32,
            lineHeight: '32px',
        },
        arrow: {
            fontSize: 32,
        },
    }),
    { name: 'Marcaje' },
);

export default function Marcaje({ marcaje }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <Typography variant='body2'>Entrada</Typography>
                <Typography className={classes.tiempo}>{format(marcaje.hora_entrada, 'HH:mm')}</Typography>
            </div>

            <ArrowForwardIcon className={classes.arrow} />

            <div>
                <Typography variant='body2'>Salida</Typography>
                <Typography className={classes.tiempo}>
                    {marcaje.hora_salida ? format(marcaje.hora_salida, 'HH:mm') : '--:--'}
                </Typography>
            </div>
        </div>
    );
}

Marcaje.propTypes = {
    marcaje: PropTypes.object.isRequired,
};
