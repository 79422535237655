import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Button from '../../common/Button';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import MasterItem from '../../common/forms/MasterDetailsViewOld/MasterItem';
import { HistoricoHorarios } from './HistoricoHorarios';
import NuevoHorarioDialog from './NuevoHorarioDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            gap: `${theme.spacing(3)}px`,
            '&>div': {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: `${theme.spacing(2)}px`,
            },
        },
        empty: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            margin: theme.spacing(6, 'auto'),
            gap: `${theme.spacing(3)}px`,
            width: 430,
        },
        emptyText: {
            color: theme.palette.neutral.grey4,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'HorariosTab' },
);

export default function HorariosTab() {
    const classes = useStyles();

    const { data: operario } = useEditFormData();

    return (
        <div className={classes.root}>
            {operario.horarios.length === 0 ? (
                <Paper elevation={0}>
                    <div className={classes.empty}>
                        <img src='/images/empty-partes-trabajo.png' />
                        <Typography variant='h1'>Horario de trabajo</Typography>
                        <Typography variant='body2' className={classes.emptyText}>
                            Configura aquí el horario de trabajo del operario. Se utiliza para los fichajes y para
                            calcular los dias de vacaciones.
                        </Typography>

                        <NuevoHorarioDialog button={<Button color='info'>Añadir el horario de trabajo</Button>} />
                    </div>
                </Paper>
            ) : (
                <>
                    <div>
                        <MasterItem
                            icon={ScheduleIcon}
                            title='Horario actual'
                            header={
                                'Este horario se utiliza para calcular la jornada de trabajo en el cómputo de fichajes y vacaciones'
                            }
                            isSelected={true}
                        />
                        <div className={classes.actions}>
                            <NuevoHorarioDialog button={<Button color='info'>Registrar un cambio de horario</Button>} />
                        </div>
                    </div>
                    <div>
                        <HistoricoHorarios classes={classes} />
                    </div>
                </>
            )}
        </div>
    );
}

HorariosTab.propTypes = {};
