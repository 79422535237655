import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ListaFichajes from './FicharView/ListaFichajesHoy';
import Reloj from './FicharView/Reloj';
import { HistorialFichajes } from './HistoricoView/HistorialFichajes';

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            width: 400,
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: theme.palette.neutral.grey3,
        },
        content: {
            padding: 0,
        },
        closeButton: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
        },
    }),
    { name: 'FicharView' },
);
export default function FicharView({ open, onClose }) {
    const classes = useStyles();
    const [showHistorico, setShowHistorico] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.paper,
            }}
        >
            {!showHistorico && (
                <IconButton onClick={onClose} className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            )}
            <div className={classes.content}>
                {showHistorico ? (
                    <HistorialFichajes onVolver={() => setShowHistorico(false)} />
                ) : (
                    <>
                        <Reloj />
                        <ListaFichajes onShowHistorico={() => setShowHistorico(true)} />
                    </>
                )}
            </div>
        </Dialog>
    );
}

FicharView.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
