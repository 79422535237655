import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';

const EstadoChip = withStyles((theme) => ({
    root: {
        width: 100,
        '&.PENDIENTE': {
            backgroundColor: '#E9EAEF',
            color: '#8F95AF',
        },
        '&.REVISADO': {
            backgroundColor: '#F3AC3D',
            color: 'white',
        },
        '&.VERIFICADO': {
            backgroundColor: '#2CBD96',
            color: 'white',
        },
    },
    icon: {
        color: 'inherit',
    },
}))(Chip);

export default EstadoChip;

export const EstadoRevisionChip = withStyles((theme) => ({
    root: {
        '&.no_revisado': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
        },
        '&.revisado': {
            backgroundColor: theme.palette.success.main,
            color: 'white',
        },
    },
    icon: {
        color: 'inherit',
    },
}))(Chip);
