import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { isEstadoFinal } from '../../../api/presupuestos';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import EmptyPresupuestoHero from '../EmptyPresupuestoHero';
import PresupuestoTreeViewTable, { COLUMN_TYPE_NUMBER } from '../PresupuestoTreeViewTable/PresupuestoTreeViewTable';
import ReordenarLineasDialog from '../ReordenarLineasDialog';
import TotalesPresupuesto from '../TotalesPresupuesto';
import UploadFileDialog from '../UploadFileDialog';
import AddCapituloDialog from './AddCapituloDialog';
import AplicarMargenDialog from './AplicarMargenDialog';
import { LineasOptionsButton } from './LineasOptionsButton';
import PresupuestoLinea from './PresupuestoLinea';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            gap: `${theme.spacing(2)}px`,
        },
    }),
    { name: 'PresupuestoTable' },
);

export default function PresupuestoTable() {
    const classes = useStyles();
    const { id, data: presupuesto, fetchData } = useEditFormData();

    if (presupuesto.lineas.length === 0) return <EmptyPresupuestoHero onSave={fetchData} presupuestoId={id} />;

    return (
        <div className={classes.root}>
            <BasePresupuestoTable presupuesto={presupuesto} fetchData={fetchData} />
            <TotalesPresupuesto presupuesto={presupuesto} />
        </div>
    );
}

export function BasePresupuestoTable({ presupuesto, fetchData, title = 'Lineas del presupuesto' }) {
    const lineas = presupuesto.lineas;

    useEffect(() => {
        if (lineas === undefined) fetchData();
    }, [lineas]);

    const columns = useMemo(
        () => [
            {
                header: 'Unidades',
                type: COLUMN_TYPE_NUMBER,
                extraProps: {
                    colSpan: 2,
                },
            },
            {
                header: 'Precio coste',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Precio venta',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Margen',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Total coste',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Total venta',
                type: COLUMN_TYPE_NUMBER,
            },
            {
                header: 'Acciones',
                type: COLUMN_TYPE_NUMBER,
            },
        ],
        [],
    );

    const LineasOptionsComponent = useCallback(
        (props) => (
            <LineasOptionsButton {...props}>
                {({ renderButtonComponentMenuItem }) => [
                    renderButtonComponentMenuItem('Importar', UploadFileDialog, {
                        presupuestoId: presupuesto.id,
                        onSave: () => fetchData(),
                    }),
                    renderButtonComponentMenuItem('Ordenar capítulos', ReordenarLineasDialog, {
                        presupuestoId: presupuesto.id,
                        lineas: lineas ?? [],
                        onSave: () => fetchData(),
                    }),
                    renderButtonComponentMenuItem('Aplicar margen a partidas', AplicarMargenDialog, {
                        presupuestoId: presupuesto.id,
                        onSave: () => fetchData(),
                    }),
                ]}
            </LineasOptionsButton>
        ),
        [presupuesto.id, lineas],
    );

    return (
        <PresupuestoTreeViewTable
            title={title}
            presupuesto={presupuesto}
            columns={columns}
            onSave={fetchData}
            LineaComponent={PresupuestoLinea}
            LineaComponentProps={{
                presupuestoId: presupuesto.id,
                editable: !isEstadoFinal(presupuesto.estado),
            }}
            LineasOptionsComponent={LineasOptionsComponent}
            extraActions={
                <>
                    <AddCapituloDialog
                        presupuestoId={presupuesto.id}
                        tooltip='Añadir capítulo'
                        button={
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        }
                        onSave={fetchData}
                    />
                </>
            }
        />
    );
}

BasePresupuestoTable.propTypes = {
    presupuesto: PropTypes.any,
    fetchData: PropTypes.func,
    title: PropTypes.string,
};
