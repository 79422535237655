import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { albaranesCompraProvider } from '../../api';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import TareaField from '../common/fields/TareaField';
import DialogForm from '../common/forms/DialogForm';

const ImputarMaterialesSchema = Yup.object().shape({
    tarea: Yup.mixed().required('Selecciona un servicio'),
    fecha: Yup.date().typeError('Fecha inválida').required('Requerido'),
    margen: Yup.number().typeError('Número inválido').required('Requerido').min(0, 'Debe ser mayor o igual a 0'),
});

function ImputarMaterialesDialog({ albaranCompraId, selectedRows, onSave, ...props }) {
    return (
        <DialogForm
            title='Imputar materiales a un servicio'
            FormikProps={{
                initialValues: {
                    tarea: null,
                    fecha: new Date(),
                    margen: 0,
                },
                validationSchema: ImputarMaterialesSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    setSubmitting(false);
                    console.log(values);

                    albaranesCompraProvider
                        .imputar(
                            albaranCompraId,
                            values.tarea.id,
                            values.fecha,
                            selectedRows.map((row) => row.id),
                            values.margen,
                        )
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ touched, errors }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TareaField
                            error={touched.tarea && !!errors.tarea}
                            helperText={touched.tarea && errors.tarea}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            name='fecha'
                            label='Fecha de imputación'
                            format={'dd/MM/yyyy'}
                            fullWidth
                            autoOk
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name='margen'
                            label='Margen de venta'
                            fullWidth
                            type='number'
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(ImputarMaterialesDialog);

ImputarMaterialesDialog.propTypes = {
    albaranCompraId: PropTypes.any,
    onClose: PropTypes.any,
    onSave: PropTypes.any,
    selectedRows: PropTypes.any,
};
