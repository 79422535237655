import * as Yup from 'yup';
import { useSnackbar } from 'material-ui-snackbar-provider';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { companiesProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import DialogForm from '../../common/forms/DialogForm';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

const ClientesSchema = Yup.object().shape({
    nombre: Yup.string().required('Requerido'),
    numero_cuenta: Yup.string().required('Requerido'),
});

function EditProfileDialog(props) {
    const snackbar = useSnackbar();

    const {
        // eslint-disable-next-line camelcase
        data,
        fetchData,
    } = useEditFormData('datos_pago');

    return (
        <DialogForm
            title='Editar datos de pago'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    nombre: data ? data.nombre : '',
                    numero_cuenta: data ? data.numero_cuenta : '',
                },
                validationSchema: ClientesSchema,
                onSubmit: (values, { setSubmitting, setFieldError }) => {
                    companiesProvider
                        .getAll('profile/datos_pago', {
                            method: 'post',
                            body: JSON.stringify(values),
                        })
                        .then(() => {
                            fetchData();
                            setSubmitting(false);
                            props.onClose();
                            snackbar.showMessage('Los cambios se han guardado con éxito');
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                            snackbar.showMessage('Ha ocurrido un error');
                        });
                },
            }}
            {...props}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.7,
                                fontSize: '0.8rem',
                            }}
                        >
                            <InfoIcon style={{ fontSize: 'inherit', marginRight: 4 }} />
                            En estos momentos solo aceptamos recibo bancario como forma de pago.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='nombre' label='Nombre del pagador' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField name='numero_cuenta' label='IBAN' fullWidth />
                    </Grid>
                </Grid>
            )}
        </DialogForm>
    );
}

export default withButtonOpener(EditProfileDialog);

EditProfileDialog.propTypes = {
    onClose: PropTypes.any,
};
