import React, { useState } from 'react';
import AlertDialog from './AlertDialog';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

function MyButtonDialog({ button, tooltip, onConfirm, onClose, ...props }, ref) {
    const [open, setOpen] = useState(false);

    let formButton = React.cloneElement(button, {
        ref: ref,
        onClick: (ev) => {
            setOpen(true);
            button.props.onClick && button.props.onClick(ev);
        },
    });

    if (tooltip) {
        formButton = (
            <Tooltip arrow title={tooltip}>
                {formButton}
            </Tooltip>
        );
    }

    return (
        <>
            {formButton}
            <AlertDialog
                onClose={(ev) => {
                    setOpen(false);
                    onClose && onClose(ev);
                }}
                onConfirm={(ev) => {
                    setOpen(false);
                    onConfirm && onConfirm(ev);
                }}
                open={open}
                {...props}
            />
        </>
    );
}

export const ButtonDialog = React.forwardRef(MyButtonDialog);

MyButtonDialog.propTypes = {
    button: PropTypes.any,
    onClick: PropTypes.any,
    onClose: PropTypes.any,
    onConfirm: PropTypes.any,
    tooltip: PropTypes.any,
};
