import { useCallback, useEffect, useMemo } from 'react';
import { eventosProvider } from '../../../api';
import { accionLabels } from '../../../api/log_eventos';
import { useFetchData } from '../../../hooks/useFetchData';
import { formatFullDateTime } from '../../../utils';
import { DateColumnFilter, dateFilterFn } from '../../common/list/DateColumnFilter';
import BaseListView, { withTableState } from '../../common/list/ListView';

const ListView = withTableState('eventos', BaseListView);

export default function EventosTab() {
    const fetchDataFn = useCallback(() => eventosProvider.getAll(), []);
    const { data, fetchData } = useFetchData(fetchDataFn);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const columns = useMemo(
        () => [
            {
                Header: 'Fecha',
                accessor: 'fecha',
                Cell: ({ value }) => formatFullDateTime(new Date(value), true),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Usuario',
                accessor: 'user.username',
            },
            {
                Header: 'Acción',
                accessor: (row) => {
                    const label = accionLabels[row.accion];

                    if (typeof label === 'function') {
                        return label(row);
                    }

                    return label ?? row.accion;
                },
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: false,
        }),
        [],
    );

    return (
        <ListView tableTitle='Lista de eventos' basePath='/eventos' columns={columns} data={data} options={options} />
    );
}
