import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Button from '../Button';

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        content: {
            display: 'flex',
            flexDirection: 'column',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
        },
    }),
    { name: 'DialogForm' },
);

function DialogForm({
    open,
    onClose,
    title,
    children,
    FormikProps,
    className,
    classes: externalClasses,
    maxWidth = 'sm',
}) {
    const classes = useStyles();

    return (
        <Dialog
            onClose={onClose}
            open={open}
            classes={{
                paper: clsx(classes.root, className),
            }}
            fullWidth={true}
            maxWidth={maxWidth}
        >
            <Formik enableReinitialize {...FormikProps}>
                {(formikProps) => {
                    return (
                        <Form className={classes.form}>
                            <DialogTitle>{title}</DialogTitle>
                            <DialogContent className={clsx(classes.content, externalClasses?.content)}>
                                {children(formikProps)}
                            </DialogContent>
                            <DialogActions>
                                <Button color='outlined' disabled={formikProps.isSubmitting} onClick={onClose}>
                                    Cancelar
                                </Button>
                                <Button
                                    type='submit'
                                    color='info'
                                    disabled={formikProps.isSubmitting}
                                    startIcon={
                                        formikProps.isSubmitting ? (
                                            <CircularProgress size={16} style={{ color: 'rgba(0, 0, 0, 0.26)' }} />
                                        ) : null
                                    }
                                >
                                    Guardar
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}

export default DialogForm;

DialogForm.propTypes = {
    FormikProps: PropTypes.any,
    children: PropTypes.any,
    className: PropTypes.any,
    maxWidth: PropTypes.string,
    onClose: PropTypes.any,
    open: PropTypes.any,
    title: PropTypes.any,
    classes: PropTypes.any,
};
