import DataProvider from './DataProvider';

export const empty = {
    codigo: '',
    nombre: '',
    cliente: null,
    cliente_id: null,
    direccion: null,
    direccion_id: null,
    adjuntos: [],
    operarios: [],
    fecha_inicio: null,
    fecha_fin: null,
    descuento: 0,
    usar_presupuestos: false,
    usar_avances: false,
};

class ProyectosProvider extends DataProvider {
    constructor() {
        super('proyectos', empty);
    }

    getLibroMantenimientoPdfUrl = (id) => this.generateUrl(`${id}/libro-mantenimiento-pdf`);

    getTareas = async (id) => this.actionOnId(id, 'tareas', null, { method: 'get' });
    getPresupuestos = async (id) => this.actionOnId(id, 'presupuestos', null, { method: 'get' });

    generarAlbaran = async (proyectoId, selectedJornadas) => {
        return this.actionOnId(proyectoId, 'albaranes', { ids: selectedJornadas });
    };

    updateAvanceLinea = async (id, linea) =>
        this.getAll(`avances-obra/${id}/lineas`, { method: 'post', body: JSON.stringify(linea) });

    deleteAvanceObra = async (id) => this.getAll(`avances-obra/${id}`, { method: 'delete' });
    certificarAvanceObra = async (id) => this.getAll(`avances-obra/${id}/certificar`, { method: 'post' });

    deleteCertificacionObra = async (id) => this.getAll(`certificaciones-obra/${id}`, { method: 'delete' });

    getCertificacionPdfURL = (id, params) =>
        this.generateUrl(`certificaciones-obra/${id}/pdf?${new URLSearchParams(params).toString()}`);

    generarCertificacionPdf = (id, params) =>
        this.getAll(`certificaciones-obra/${id}/pdf-async?${new URLSearchParams(params).toString()}`);
}

export const dataProvider = new ProyectosProvider();
