import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { tareasProvider } from '../../../api';
import MasterItemOptions from '../../common/forms/MasterDetailsView/MasterItemOptions';
import DelayedDownloadComponent from '../AlbaranList/DelayedDownloadComponent';
import GenerarAlbaranForTareaDialog from './GenerarAlbaranForTareaDialog';
import GenerarPdfDialog from './GenerarPdfDialog';

export default function ParteTrabajoListOptions({ tareaId, canGenerarAlbaran, onUpdate }) {
    const snackbar = useSnackbar();

    if (!tareaId) return null;

    return (
        <MasterItemOptions>
            {({ closeMenu }) => [
                <DelayedDownloadComponent
                    key={1}
                    Component={MenuItem}
                    onClick={() => tareasProvider.getAll(`${tareaId}/exportar-async`)}
                    closeMenu={() => {}}
                    color='outlined'
                    altDownloadAction={() => {
                        tareasProvider.fetchAttachment(`${tareaId}/exportar`, { method: 'get' }).catch((err) => {
                            snackbar.showMessage(err.body.message);
                        });
                    }}
                >
                    Descargar todo en Excel
                </DelayedDownloadComponent>,
                <GenerarPdfDialog
                    key={2}
                    tareaId={tareaId}
                    button={<MenuItem onClick={closeMenu}>Descargar todo en PDF</MenuItem>}
                />,
                <GenerarAlbaranForTareaDialog
                    key={3}
                    button={
                        <MenuItem onClick={closeMenu} disabled={!canGenerarAlbaran}>
                            Generar albarán de servicio
                        </MenuItem>
                    }
                />,
            ]}
        </MasterItemOptions>
    );
}

ParteTrabajoListOptions.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    tareaId: PropTypes.string,
    canGenerarAlbaran: PropTypes.bool,
};
