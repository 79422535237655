import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { tareasProvider } from '../../../api';
import useAuthState from '../../../AuthState';
import DialogPaper from './DialogPaper';
import Observaciones from './Observaciones';

const useStyles = makeStyles(
    (theme) => ({
        empty: {
            padding: theme.spacing(1.5),
            color: theme.palette.neutral.primary,
        },
    }),
    { name: 'DialogObservacionesPaper' },
);

export function useExtractJornadasObservaciones(id, open = true) {
    const [jornadasObservaciones, setJornadasObservaciones] = useState([]);
    const [jornadasIncidencias, setJornadasIncidencias] = useState([]);

    const {
        userInfo: {
            preferencias: { usar_incidencias: usarIncidencias },
        },
    } = useAuthState();

    function extractJornadasObservaciones(jornadas) {
        const observaciones = jornadas.map((jornada) => ({
            jornadaId: jornada.id,
            fecha: jornada.fecha,
            operario: jornada.operario,
            texto: '',
            vista: true,
            fecha_actualizacion: null,
            ...jornada.notas[0],
            fotos: jornada.fotos,
            incidencia: usarIncidencias && jornada.notas.length > 0 ? jornada.notas[0].incidencia : false,
        }));
        setJornadasObservaciones(observaciones.filter((o) => !usarIncidencias || !o.incidencia));
        if (usarIncidencias) setJornadasIncidencias(observaciones.filter((o) => o.incidencia));
    }

    useEffect(() => {
        if (!open) {
            setJornadasIncidencias([]);
            setJornadasObservaciones([]);
            return;
        }
        tareasProvider.actionOnId(id, 'notas', null, { method: 'get' }).then(extractJornadasObservaciones);
    }, [id, open]);

    return [jornadasObservaciones, jornadasIncidencias, extractJornadasObservaciones];
}

export default function DialogObservacionesPaper({ id, onUpdate }) {
    const classes = useStyles();

    const {
        userInfo: {
            preferencias: { usar_incidencias: usarIncidencias },
        },
    } = useAuthState();

    const [jornadasObservaciones, jornadasIncidencias, extractJornadasObservaciones] =
        useExtractJornadasObservaciones(id);

    return (
        <>
            <DialogPaper title='Observaciones y fotos de los operarios'>
                {jornadasObservaciones.length === 0 && (
                    <div className={classes.empty}>
                        <Typography variant='subtitle2'>No hay observaciones</Typography>
                    </div>
                )}
                {jornadasObservaciones.map((observacion, i) => (
                    <Observaciones
                        key={i}
                        observacion={observacion}
                        onRead={() => {
                            tareasProvider.updateJornadaNotaVista(observacion.jornadaId).then((jornadas) => {
                                extractJornadasObservaciones(jornadas);
                                onUpdate && onUpdate(jornadas);
                            });
                        }}
                        onDelete={() => {
                            tareasProvider.deleteJornadaNotas(observacion.jornadaId).then((jornadas) => {
                                extractJornadasObservaciones(jornadas);
                                onUpdate && onUpdate(jornadas);
                            });
                        }}
                        onDeleteFoto={(fotoId) => {
                            tareasProvider.deleteFoto(fotoId).then(({ fotos }) => {
                                const jornadas = jornadasObservaciones.map((j) => {
                                    if (j.jornadaId === observacion.jornadaId) {
                                        return { ...j, fotos };
                                    }
                                    return j;
                                });
                                extractJornadasObservaciones(jornadas);
                                onUpdate && onUpdate(jornadas);
                            });
                        }}
                    />
                ))}
            </DialogPaper>
            {usarIncidencias && (
                <DialogPaper title='Incidencias'>
                    {jornadasIncidencias.length === 0 && (
                        <div className={classes.empty}>
                            <Typography variant='subtitle2'>No hay incidencias</Typography>
                        </div>
                    )}
                    {jornadasIncidencias.map((observacion, i) => (
                        <Observaciones
                            key={i}
                            observacion={observacion}
                            onUpdateResolucion={(resolucion) => {
                                tareasProvider
                                    .updateJornadaNotaIncidenciaResolucion(observacion.jornadaId, resolucion)
                                    .then((jornadas) => {
                                        extractJornadasObservaciones(jornadas);
                                        onUpdate && onUpdate(jornadas);
                                    });
                            }}
                            onResolver={(resolver) => {
                                tareasProvider
                                    .updateJornadaNotaIncidenciaResuelta(observacion.jornadaId, resolver)
                                    .then((jornadas) => {
                                        extractJornadasObservaciones(jornadas);
                                        onUpdate && onUpdate(jornadas);
                                    });
                            }}
                            onRead={() => {
                                tareasProvider.updateJornadaNotaVista(observacion.jornadaId).then((jornadas) => {
                                    extractJornadasObservaciones(jornadas);
                                    onUpdate && onUpdate(jornadas);
                                });
                            }}
                            onDelete={() => {
                                tareasProvider.deleteJornadaNotas(observacion.jornadaId).then((jornadas) => {
                                    extractJornadasObservaciones(jornadas);
                                    onUpdate && onUpdate(jornadas);
                                });
                            }}
                            onDeleteFoto={(fotoId) => {
                                tareasProvider.deleteFoto(fotoId).then(({ fotos }) => {
                                    const jornadas = jornadasIncidencias.map((j) => {
                                        if (j.jornadaId === observacion.jornadaId) {
                                            return { ...j, fotos };
                                        }
                                        return j;
                                    });
                                    extractJornadasObservaciones(jornadas);
                                    onUpdate && onUpdate(jornadas);
                                });
                            }}
                        />
                    ))}
                </DialogPaper>
            )}
        </>
    );
}

DialogObservacionesPaper.propTypes = {
    id: PropTypes.any.isRequired,
    onUpdate: PropTypes.func,
};
