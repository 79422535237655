import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiTableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        title: {
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            '& .MuiTypography-root': {
                flex: 1,
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '50px',
            },
            '& .MuiIconButton-root': {
                padding: 8,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'BeneficiosPaper' },
);

const TableCell = withStyles(
    (theme) => ({
        root: {
            height: 40,
            color: 'white',
            border: 'none',
            fontWeight: theme.typography.fontWeightMedium,
            '.positivo &': {
                backgroundColor: theme.palette.success.main,
            },
            '.negativo &': {
                backgroundColor: theme.palette.error.main,
            },
            '.MuiTableRow-root:last-of-type &:first-of-type': {
                borderBottomLeftRadius: 8,
            },
            '.MuiTableRow-root:last-of-type &:last-of-type': {
                borderBottomRightRadius: 8,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

export default function BeneficiosPaper({ totalCoste, totalVenta, totalFacturado }) {
    const currency = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' });

    const classes = useStyles();

    const beneficioVenta = totalVenta - totalCoste;
    const beneficioVentaPorcentaje = beneficioVenta / totalCoste;

    const beneficioFacturado = totalFacturado - totalCoste;
    const beneficioFacturadoPorcentaje = beneficioFacturado / totalCoste;

    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.title}>
                <Typography variant='body2'>Beneficio</Typography>
            </div>
            <Table size='small'>
                <TableBody>
                    <TableRow className={beneficioVenta > 0 ? 'positivo' : 'negativo'}>
                        <TableCell>Sobre venta estimada</TableCell>
                        <TableCell>{(beneficioVentaPorcentaje * 100).toFixed()}%</TableCell>
                        <TableCell>{currency.format(beneficioVenta)}</TableCell>
                    </TableRow>
                    <TableRow className={beneficioFacturado > 0 ? 'positivo' : 'negativo'}>
                        <TableCell>Sobre facturado</TableCell>
                        <TableCell>{(beneficioFacturadoPorcentaje * 100).toFixed()}%</TableCell>
                        <TableCell>{currency.format(beneficioFacturado)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
}

BeneficiosPaper.propTypes = {
    totalCoste: PropTypes.any,
    totalFacturado: PropTypes.any,
    totalVenta: PropTypes.any,
};
