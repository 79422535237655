import PaperTable from './PaperTable';
import PropTypes from 'prop-types';
import { formatDate } from '../../../utils';
import EditarCosteMaterialesDialog from './EditarCosteMaterialesDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useMemo } from 'react';
import ViewToggler from '../../solicitudes/ViewToggler';
import useAuthState from '../../../AuthState';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AlbaranesCompraInfoTooltip from '../AlbaranesCompraInfoTooltip';

const useStyles = makeStyles(
    (theme) => ({
        descripcion: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    }),
    { name: 'MaterialesPaper' },
);
export default function MaterialesPaper({ rows, totales, desglosado, setDesglosado, albaranesCompraMap }) {
    const classes = useStyles();
    const {
        userInfo: {
            preferencias: { usar_precios: usarPrecios },
        },
    } = useAuthState();

    const columns = useMemo(
        () =>
            desglosado
                ? [
                      {
                          label: 'Descripción',
                          renderCell: (row) => {
                              const albaranCompraId = albaranesCompraMap.map[row.albaran_compra_linea_id];

                              return (
                                  <div className={classes.descripcion}>
                                      {row.descripcion}{' '}
                                      {albaranCompraId ? (
                                          <AlbaranesCompraInfoTooltip
                                              albaranes={[albaranesCompraMap.albaranes[albaranCompraId]]}
                                          />
                                      ) : null}
                                  </div>
                              );
                          },
                          footer: 'Total',
                      },
                      {
                          label: 'Unidades',
                          renderCell: (row) => row.unidades,
                          isNumber: true,
                          footer: null,
                      },

                      ...(usarPrecios
                          ? [
                                {
                                    label: 'Precio coste',
                                    renderCell: (row) => `${row.precio_unitario_coste.toFixed(2)} €`,
                                    isNumber: true,
                                    footer: null,
                                },
                                {
                                    label: 'Precio venta',
                                    renderCell: (row) => `${row.precio_unitario.toFixed(2)} €`,
                                    isNumber: true,
                                    footer: null,
                                },
                                {
                                    label: 'Dto venta',
                                    renderCell: (row) => `${row.descuento.toFixed(2)} %`,
                                    isNumber: true,
                                    footer: null,
                                },
                                {
                                    label: 'Total coste',
                                    renderCell: (row) =>
                                        row.importe_coste !== null ? `${row.importe_coste.toFixed(2)}€` : 'NaN',
                                    isNumber: true,
                                },
                                {
                                    label: 'Total venta',
                                    renderCell: (row) => (row.importe !== null ? `${row.importe.toFixed(2)}€` : 'NaN'),
                                    isNumber: true,
                                },
                            ]
                          : []),
                  ]
                : [
                      {
                          label: 'Fecha',
                          renderCell: (row) => {
                              const albaranesCompra = row.materiales
                                  .map((m) => albaranesCompraMap.map[m.albaran_compra_linea_id])
                                  .filter(Boolean)
                                  .map((id) => albaranesCompraMap.albaranes[id]);

                              return (
                                  <div>
                                      {formatDate(row.fecha)}
                                      {row.materiales && (
                                          <EditarCosteMaterialesDialog
                                              fecha={row.fecha}
                                              button={
                                                  <IconButton size='small'>
                                                      <EditIcon fontSize='inherit' />
                                                  </IconButton>
                                              }
                                              materiales={row.materiales}
                                              albaranesCompraMap={albaranesCompraMap}
                                          />
                                      )}
                                      {albaranesCompra.length > 0 && (
                                          <AlbaranesCompraInfoTooltip albaranes={albaranesCompra} />
                                      )}
                                  </div>
                              );
                          },
                          footer: 'Total',
                      },
                      ...(usarPrecios
                          ? [
                                {
                                    label: 'Total coste',
                                    renderCell: (row) => `${row.importeCoste.toFixed(2)} €`,
                                    isNumber: true,
                                },
                                {
                                    label: 'Total venta',
                                    renderCell: (row) => `${row.importeVenta.toFixed(2)} €`,
                                    isNumber: true,
                                },
                            ]
                          : []),
                  ],
        [desglosado, usarPrecios, albaranesCompraMap],
    );

    return (
        <PaperTable
            columns={columns}
            title='Materiales'
            rows={rows}
            footerRow={usarPrecios ? totales : null}
            titleActions={
                <ViewToggler
                    views={[
                        {
                            key: 'agrupado',
                            label: 'Agrupado',
                        },
                        {
                            key: 'desglosado',
                            label: 'Desglosado',
                        },
                    ]}
                    initialView='agrupado'
                    onViewChange={(view) => setDesglosado(view === 'desglosado')}
                />
            }
        />
    );
}

MaterialesPaper.propTypes = {
    albaranesCompraMap: PropTypes.any,
    desglosado: PropTypes.any,
    rows: PropTypes.any,
    setDesglosado: PropTypes.any,
    totales: PropTypes.any,
};
