import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Button from '../../common/Button';
import DialogPaper from './DialogPaper';

const useStyles = makeStyles(
    (theme) => ({
        body: {
            padding: theme.spacing(2),
        },
        textarea: {
            '& .MuiInput-root': {
                fontSize: 14,
            },
            '& .Mui-disabled': {
                color: theme.palette.text.primary,
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderWidth: 0,
            },
        },
        actions: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
    { name: 'DialogEditablePaper' },
);

export default function DialogEditablePaper({ title, value: initialValue, placeholder, onSave, className }) {
    const classes = useStyles();

    const [editing, setEditing] = useState(false);

    const [value, setValue] = useState(initialValue || '');

    useEffect(() => {
        setValue(initialValue || '');
        setEditing(false);
    }, [initialValue]);

    return (
        <DialogPaper
            title={title}
            actions={
                !editing && (
                    <IconButton onClick={() => setEditing(true)}>
                        <EditIcon />
                    </IconButton>
                )
            }
            className={className}
        >
            <div className={classes.body}>
                <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    value={value}
                    disabled={!editing}
                    placeholder={placeholder}
                    onChange={(ev) => setValue(ev.target.value)}
                    className={classes.textarea}
                />
            </div>
            <Collapse in={editing}>
                <div className={classes.actions}>
                    <Button
                        color='secondary'
                        onClick={() => {
                            setEditing(false);
                            setValue(initialValue);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button color='primary' onClick={() => onSave(value)}>
                        Guardar
                    </Button>
                </div>
            </Collapse>
        </DialogPaper>
    );
}

DialogEditablePaper.propTypes = {
    className: PropTypes.any,
    onSave: PropTypes.any,
    placeholder: PropTypes.any,
    title: PropTypes.any,
    value: PropTypes.any,
};
