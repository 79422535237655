import makeStyles from '@material-ui/core/styles/makeStyles';
import SettingsIcon from '@material-ui/icons/Settings';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { tareasProvider } from '../../../api';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import Button from '../../common/Button';
import DialogEditor from '../../common/forms/DialogEditor';
import { GenerarPdfDialogSchema, GenerarPdfForm, useGenerarPdfDialogDefaultParams } from './GenerarPdfDialog';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: 600,
        },
        body: {
            gap: `${theme.spacing(2)}px`,
        },
        title: {
            color: theme.palette.neutral.grey4,
        },
        fields: {
            gap: `${theme.spacing(2)}px`,
        },
        field: {
            flex: 1,
        },
    }),
    { name: 'EnviarAlbaranDialog' },
);

// check that emails is a comma separated list of emails
const EnviarAlbaranSchema = Yup.object().shape({
    emails: Yup.string()
        .required('Requerido')
        .test('is-emails', 'Debe ser una lista de correos válida', (value) => {
            return value && value.split(',').every((email) => Yup.string().email().isValidSync(email.trim()));
        }),
    asunto: Yup.string().required('Requerido'),
    pdf_params: GenerarPdfDialogSchema,
});

const defaultEnvioInfo = {
    emails: '',
    asunto: '',
    mensaje: '',
};

function EnviarAlbaranDialog({ albaran, onSave, open, ...props }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [envioInfo, setEnvioInfo] = useState(defaultEnvioInfo);
    const generarPdfParams = useGenerarPdfDialogDefaultParams();

    useEffect(() => {
        if (!open) {
            setEnvioInfo({ ...defaultEnvioInfo, pdf_params: generarPdfParams });
            return;
        }

        tareasProvider
            .getAlbaranEnvioInfo(albaran.id)
            .then((envioInfo) => setEnvioInfo({ ...envioInfo, pdf_params: generarPdfParams }));
    }, [albaran, generarPdfParams, open]);

    return (
        <Formik
            initialValues={envioInfo}
            validationSchema={EnviarAlbaranSchema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError }) => {
                // Retornar una nueva promesa para controlar el flujo de errores
                return new Promise((resolve, reject) => {
                    function onError(message) {
                        for (const [field, errors] of Object.entries(message)) {
                            setFieldError(field, errors.join('\n'));
                        }
                        setSubmitting(false);
                        reject(new Error('Error en la validación del formulario'));
                    }

                    // Proceso para enviar el albarán
                    tareasProvider
                        .enviarAlbaran(albaran.id, values)
                        .then((res) => {
                            function checkStatus() {
                                fetch(res.status_url)
                                    .then((res) => res.json())
                                    .then((res) => {
                                        if (res.state === 'SUCCESS') {
                                            onSave(res.result);
                                            setSubmitting(false);
                                            clearTimeout(timeout);
                                            resolve(); // Resolución exitosa
                                            props.onClose();
                                        } else if (res.state === 'FAILURE') {
                                            onError(JSON.parse(res.status));
                                            clearTimeout(timeout);
                                            reject(new Error('Error al enviar el albarán')); // Rechazar con error
                                        } else {
                                            timeout = setTimeout(checkStatus, 1000);
                                        }
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        snackbar.showMessage(err.body.message);
                                        clearTimeout(timeout);
                                        reject(new Error('Error en la comunicación con el servidor')); // Rechazar en caso de fallo
                                    });
                            }
                            let timeout = setTimeout(checkStatus, 1000);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                onError(err.message);
                            }
                            setSubmitting(false);
                            reject(new Error('Error en el envío del albarán')); // Rechazar la promesa en caso de error
                        });
                });
            }}
        >
            {({ isSubmitting, submitForm }) => {
                const [showPdfParams, setShowPdfParams] = useState(false);
                useEffect(() => {
                    if (!open) setShowPdfParams(false);
                }, [open]);

                console.log('issubmitting', isSubmitting);
                return (
                    <Form>
                        <DialogEditor
                            title='Enviar albarán de servicio'
                            onSave={submitForm}
                            classes={{
                                root: classes.root,
                                body: classes.body,
                            }}
                            open={open}
                            canSave={!isSubmitting}
                            saveButtonText='Enviar'
                            {...props}
                        >
                            <div className={classes.fields}>
                                <TextField
                                    name='emails'
                                    label='Para'
                                    fullWidth
                                    helperText='Puedes añadir varias direcciones separadas por comas'
                                />
                            </div>
                            <div className={classes.fields}>
                                <TextField name='asunto' label='Asunto' fullWidth />
                            </div>
                            <div className={classes.fields}>
                                <TextField
                                    name='mensaje'
                                    label='Mensaje'
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                            {showPdfParams ? (
                                <GenerarPdfForm fieldName='pdf_params' label='Configuración del PDF' />
                            ) : (
                                <div className={classes.fields}>
                                    <Button
                                        color='transparent'
                                        onClick={() => setShowPdfParams(true)}
                                        startIcon={<SettingsIcon />}
                                        style={{ paddingLeft: 4 }}
                                    >
                                        Configurar cómo se genera el PDF
                                    </Button>
                                </div>
                            )}
                        </DialogEditor>
                    </Form>
                );
            }}
        </Formik>
    );
}

EnviarAlbaranDialog.propTypes = {
    albaran: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.any,
    open: PropTypes.any,
};

export default withButtonOpener(EnviarAlbaranDialog);
