import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { solicitudesProvider } from '../../api';
import { usePermisos } from '../../AuthState';
import { ListItem } from './AppBarMenu';
import ButtonMenu, { MenuBadge } from './ButtonMenu';

export default function OperariosMenu({ disabled }) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [badgeState, setBadgeState] = useState({
        solicitudesPendientes: 0,
    });
    const [gestionarEmpleados, gestionarFichajes, gestionarVacacionesAusencias] = usePermisos(
        'gestionar_empleados',
        'gestionar_fichajes',
        'gestionar_vacaciones_ausencias',
    );

    useEffect(() => {
        if (!menuIsOpen) return;

        Promise.all([solicitudesProvider.getAll('vacaciones-pendientes-count')])
            .then(([vacacionesPendientes]) => {
                setBadgeState({
                    solicitudesPendientes: vacacionesPendientes.count,
                });
            })
            .catch(() => {
                console.error('No se han podido obtener los datos de las incidencias y partes sin revisar');
            });
    }, [menuIsOpen]);

    const items = useMemo(() => {
        const items = [];
        if (gestionarEmpleados) {
            items.push({
                path: '/empleados',
                label: 'Empleados',
            });
        }
        if (gestionarFichajes) {
            items.push({
                path: '/fichajes',
                label: 'Fichajes',
            });
        }
        if (gestionarVacacionesAusencias) {
            items.push({
                path: '/solicitudes',
                label: (
                    <MenuBadge badgeContent={badgeState.solicitudesPendientes} color='primary'>
                        Vacaciones y ausencias
                    </MenuBadge>
                ),
            });
        }

        items.push({
            path: '/control-jornada',
            label: 'Control de jornada',
        });

        return items;
    }, [badgeState, gestionarEmpleados, gestionarFichajes, gestionarVacacionesAusencias]);

    return (
        <ButtonMenu button={<ListItem label='Empleados' disabled={disabled} />} onOpen={setMenuIsOpen} items={items} />
    );
}

OperariosMenu.propTypes = {
    disabled: PropTypes.any,
};
