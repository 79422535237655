import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { operariosProvider } from '../../api';
import { getMarcajesFromHorarios } from '../../api/operarios';
import Button from '../common/Button';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import InfoField from '../common/fields/InfoField';
import { KeyboardTimePicker } from '../common/fields/KeyboardDatePicker';
import EntradaIcon from '../icons/Entrada';
import SalidaIcon from '../icons/Salida';
import { LocationButton } from './VerFichaje';

const colorByVariant = {
    entrada: '#2CBD96',
    salida: '#E94F4F',
};

export const MarcajeInfoField = withStyles((theme) => ({
    root: {
        margin: 0,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        color: (props) => colorByVariant[props.variant] ?? 'inherit',
        '& svg': {
            fontSize: 14,
            marginRight: theme.spacing(0.5),
        },
    },
}))(InfoField);

const useStyles = makeStyles(
    (theme) => ({
        footer: {
            display: 'flex',
            alignItems: 'center',
        },
        error: {
            flex: 1,
        },
        marcajeRow: {
            display: 'flex',
            gap: '16px',
            alignItems: 'flex-start',
        },
        marcajeField: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        locationButton: {
            padding: theme.spacing(1),
        },
    }),
    { name: 'MarcajesField' },
);

export default function MarcajesField({
    marcajes,
    fecha,
    operarioId,
    fieldName = 'marcajes',
    addButtonLabel = 'Añadir',
}) {
    const classes = useStyles();
    const [horarios, setHorarios] = useState(null);
    const [, { error }, { setValue }] = useField(fieldName);

    useEffect(() => {
        if (!operarioId) return;

        operariosProvider
            .getHorarios(operarioId)
            .then((horarios) => setHorarios(horarios.length > 0 ? horarios[0].horas : null));
    }, [operarioId]);

    useEffect(() => {
        if (!horarios || !fecha) return;

        const weekDay = (new Date(fecha).getDay() + 6) % 7;

        setValue(getMarcajesFromHorarios(horarios[weekDay], fecha));
    }, [horarios, fecha]);

    console.log(error);

    return (
        <FieldArray name={fieldName}>
            {({ remove, push }) => (
                <>
                    {marcajes.map((marcaje, idx) => (
                        <React.Fragment key={idx}>
                            <Grid item xs={12} className={classes.marcajeRow}>
                                <div className={classes.marcajeField}>
                                    <MarcajeInfoField
                                        variant='entrada'
                                        label={
                                            <>
                                                <EntradaIcon /> Entrada
                                            </>
                                        }
                                        value={
                                            <KeyboardTimePicker
                                                name={`${fieldName}.${idx}.hora_entrada`}
                                                format={'HH:mm'}
                                                autoOk
                                                ampm={false}
                                                minutesStep={5}
                                            />
                                        }
                                        hideLabel={idx > 0}
                                    />
                                    {marcaje.coords_entrada && marcaje.coords_entrada.latitude && (
                                        <LocationButton style={{ margin: 8 }} coords={marcaje.coords_entrada} />
                                    )}
                                </div>
                                <div className={classes.marcajeField}>
                                    <MarcajeInfoField
                                        variant='salida'
                                        label={
                                            <>
                                                <SalidaIcon /> Salida
                                            </>
                                        }
                                        value={
                                            <KeyboardTimePicker
                                                name={`${fieldName}.${idx}.hora_salida`}
                                                format={'HH:mm'}
                                                autoOk
                                                ampm={false}
                                                minutesStep={5}
                                            />
                                        }
                                        hideLabel={idx > 0}
                                    />
                                    {marcaje.coords_salida && marcaje.coords_salida.latitude && (
                                        <LocationButton style={{ margin: 8 }} coords={marcaje.coords_salida} />
                                    )}
                                </div>
                                <ButtonDialog
                                    button={
                                        <IconButton style={{ marginTop: idx === 0 ? 26 : 0 }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    dialogTitle='¿Borrar marcaje?'
                                    dialogText='Esta opción no se puede deshacer. ¿Deseas continuar?'
                                    onConfirm={() => remove(idx)}
                                />
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12} className={classes.footer}>
                        <Typography className={classes.error} color='error' variant='subtitle1'>
                            {typeof error === 'string' ? error : null}
                        </Typography>
                        <Button
                            color='primary'
                            startIcon={<AddIcon />}
                            onClick={() => push({ hora_entrada: new Date(), hora_salida: new Date() })}
                        >
                            {addButtonLabel}
                        </Button>
                    </Grid>
                </>
            )}
        </FieldArray>
    );
}

MarcajesField.propTypes = {
    addButtonLabel: PropTypes.string,
    fecha: PropTypes.any,
    fieldName: PropTypes.string,
    marcajes: PropTypes.any,
    operarioId: PropTypes.any,
};
