import * as Sentry from '@sentry/react';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import * as providers from './api';
import API_ROOT from './api/api-config';
import AuthService from './components/auth/AuthService';
import ChangePassword from './components/auth/ChangePassword';
import Login from './components/auth/Login';
import RecoverPassword from './components/auth/RecoverPassword';

/* Action Types */
const SET_LOGIN_INFO = 'SET_LOGIN_INFO';
const DISMISS_TEST_MESSAGE = 'DISMISS_TEST_MESSAGE';

/* Define a context and a reducer for updating the context */
const AuthStateContext = createContext();

const initialState = {
    username: null,
    authenticationToken: null,
    roles: null,
    userInfo: null,
    testMessageDismissed: false,
};

const authStateReducer = (state, action) => {
    const authService = new AuthService(API_ROOT);
    const token = authService.getToken();
    const userInfo = authService.getUserInfo();

    switch (action.type) {
        case SET_LOGIN_INFO:
            Object.keys(providers).forEach((key) => {
                providers[key].token = token;
            });
            if (userInfo) {
                Sentry.setUser({ company: userInfo.company, name: userInfo.name, username: authService.getUsername() });
                window.Beacon('identify', {
                    name: userInfo.name,
                    company: userInfo.company,
                    email: userInfo.email,
                });
            } else {
                Sentry.setUser(null);
                window.Beacon('close');
                window.Beacon('logout', { endActiveChat: true });
            }

            return {
                ...state,
                username: authService.getUsername(),
                token: token,
                roles: authService.getRoles(),
                userInfo,
                testMessageDismissed: userInfo && userInfo.payment_status !== 'FALTA_INFO',
            };

        case DISMISS_TEST_MESSAGE:
            return {
                ...state,
                testMessageDismissed: true,
            };

        default:
            return state;
    }
};

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const AuthStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authStateReducer, initialState);
    const [justLoggedIn, setJustLoggedIn] = useState(false);

    const history = useHistory();
    const snackbar = useSnackbar();
    const paymentStatus = state && state.userInfo?.payment_status;
    const primerosPasosEstado = state && state.userInfo?.primeros_pasos_estado;
    const testDaysRemaining = state && state.userInfo?.test_days_remaining;

    useEffect(() => {
        dispatch({
            type: SET_LOGIN_INFO,
        });
    }, []);

    useEffect(() => {
        if (!justLoggedIn) return;

        setJustLoggedIn(false);

        if (primerosPasosEstado === 'ACTIVO' && testDaysRemaining >= 0) {
            history.push('/mi-cuenta/aprende/primeros-pasos');
        } else if (paymentStatus === 'FALTA_INFO') {
            history.push('/mi-cuenta');
            snackbar.showMessage('Por favor, completa la informacion de tarifa y suscripción');
        }
    }, [justLoggedIn, testDaysRemaining, paymentStatus, primerosPasosEstado, history, snackbar]);

    const onLogin = (token) => {
        dispatch({
            type: SET_LOGIN_INFO,
        });
        setJustLoggedIn(true);
    };

    if (!state.token) {
        return (
            <Switch>
                <Route path='/recover-password'>
                    <RecoverPassword />
                </Route>
                <Route path='/change-password/:token'>
                    <ChangePassword />
                </Route>
                <Route path='/'>
                    <Login onLogin={onLogin} />
                </Route>
            </Switch>
        );
    }

    Object.keys(providers).forEach((key) => {
        providers[key].onError = (error) => {
            if (error.status === 401) {
                const authService = new AuthService(API_ROOT);
                authService.logout();
                dispatch({
                    type: SET_LOGIN_INFO,
                });
                history.push('/');
            } else if (error.status === 403) {
                history.push('/');
                console.error('ERROR 403', error);
            } else {
                throw error;
            }
        };
    });

    return <AuthStateContext.Provider value={[state, dispatch]}>{children}</AuthStateContext.Provider>;
};

/*
Default export is a hook that provides a simple API for updating the global state.
This also allows us to keep all of this state logic in this one file
*/

const useAuthState = () => {
    const [state, dispatch] = useContext(AuthStateContext);
    const history = useHistory();

    function updateLoginInfo() {
        dispatch({
            type: SET_LOGIN_INFO,
        });
    }

    const handleLogout = () => {
        const authService = new AuthService(API_ROOT);
        authService.logout();
        updateLoginInfo();
    };

    const handleError = (error) => {
        if (error.status === 401) {
            const authService = new AuthService(API_ROOT);
            authService.logout();
            dispatch({
                type: SET_LOGIN_INFO,
            });
        } else if (error.status === 403) {
            history.push('/');
            console.error('ERROR 403', error);
        } else {
            console.error('ERROR (other)', error);
        }
    };

    const dismissTestMessage = useCallback(() => dispatch({ type: DISMISS_TEST_MESSAGE }), [dispatch]);

    const testDaysRemaining = state?.userInfo?.test_days_remaining;
    const userInfo = state.userInfo;

    return {
        logout: handleLogout,
        onError: handleError,
        updateLoginInfo,
        testInfo: {
            daysRemaining: testDaysRemaining,
            dismissMessage: dismissTestMessage,
            isMessageDismissed: state.testMessageDismissed,
        },
        username: state.username,
        roles: state.roles,
        userInfo: state.userInfo,
        isAdmin: state.roles.includes('ADMIN'),
        isCompany: state.roles.includes('COMPANY') || state.roles.includes('ADMINISTRATIVO'),
        isAdministrativo: state.roles.includes('ADMINISTRATIVO'),
        faltaInfo: userInfo.payment_status === 'FALTA_INFO',
        isPaymentOk:
            userInfo.payment_status !== 'FALTA_INFO' ||
            userInfo.test_days_remaining === undefined ||
            userInfo.test_days_remaining === null ||
            userInfo.test_days_remaining >= 0,
    };
};

export function usePreferencias(...preferencias) {
    const {
        userInfo: { preferencias: preferenciasUser = {} },
    } = useAuthState();

    if (preferencias.length === 0) return preferenciasUser;
    else if (preferencias.length === 1) return preferenciasUser[preferencias[0]];

    return preferencias.map((key) => preferenciasUser[key]);
}

export function usePermisos(...permisos) {
    const {
        userInfo: { permisos: permisosUser = {} },
    } = useAuthState();

    if (permisos.length === 0) return permisosUser;
    else if (permisos.length === 1) return permisosUser[permisos[0]];

    return permisos.map((key) => permisosUser[key]);
}

export default useAuthState;

AuthStateProvider.propTypes = {
    children: PropTypes.any,
};
