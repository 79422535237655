import { useEffect, useState } from 'react';
import { companiesProvider } from '../../../api';
import MasterDetailsView from '../../common/forms/MasterDetailsView/MasterDetailsView';
import AprendeDetails from './AprendeDetails';
import AprendeItem from './AprendeItem';
import CentroAyuda from './CentroAyuda/CentroAyuda';
import PrimerosPasos from './PrimerosPasos/PrimerosPasos';

export default function AprendeTab() {
    const [primerosPasos, setPrimerosPasos] = useState(null);

    useEffect(() => {
        companiesProvider.getPrimerosPasos().then(setPrimerosPasos);
    }, []);

    const aprendeSections = [];

    if (primerosPasos !== null) {
        aprendeSections.push({
            id: 'primeros-pasos',
            label: 'Primeros pasos',
            content: <PrimerosPasos initialPrimerosPasos={primerosPasos.steps} />,
        });
    }

    aprendeSections.push({
        id: 'centro-de-ayuda',
        label: 'Centro de ayuda',
        content: <CentroAyuda />,
    });

    return (
        <MasterDetailsView
            title='Aprende'
            masterComponent={AprendeItem}
            detailsComponent={AprendeDetails}
            items={aprendeSections}
        />
    );
}
