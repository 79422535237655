import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import LockIcon from '@material-ui/icons/Lock';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { tareasProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import { withButtonOpener } from '../../hooks/withButtonOpener';
import { formatDateTime, formatFullDate, formatISODate, formatTiempo } from '../../utils';
import DialogEditor from '../common/forms/DialogEditor';
import JornadaDrawer from '../revision_partes/JornadaDrawer';
import MarcajesDialog from '../servicios/ParteTrabajoList/MarcajesDialog/MarcajesDialog';

const TableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0.5, 2),
            borderBottom: '1px solid #eee',
            whiteSpace: 'nowrap',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
        sizeSmall: {
            '&:last-child': {
                padding: 0,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

function ResumenJornadaOperarioDialog({ id, fecha, operario, ...props }) {
    const [resumen, setResumen] = useState([]);

    const open = props.open;

    const [drawerJornada, setDrawerJornada] = useState(false);

    const toggleDrawerJornada = (jornada) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerJornada(jornada);
    };

    function fetchData() {
        tareasProvider
            .action(`por-operario/${id}/${formatISODate(fecha)}`, null, { method: 'get' })
            .then((res) => setResumen(res.resumen));
    }

    useEffect(() => {
        if (!open) return;

        fetchData();
    }, [id, open]);

    const tiempoTotal = useMemo(
        () => createTiempo(resumen.reduce((total, item) => total + item.minutos, 0)),
        [resumen],
    );

    return (
        <DialogEditor
            title={`Resúmen del ${formatFullDate(fecha)} para ${operario}`}
            disableBodyGutters
            maxWidth='md'
            cancelButtonText='Cerrar'
            {...props}
        >
            <JornadaDrawer
                jornada={drawerJornada}
                onClose={toggleDrawerJornada(false)}
                onRevisar={(jornadaId) => tareasProvider.marcarJornadaRevisada(jornadaId).then(fetchData)}
                onUpdate={fetchData}
            />
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Cliente</TableCell>
                        <TableCell>Servicio</TableCell>
                        <TableCell>Tiempo</TableCell>
                        <TableCell>Revisado</TableCell>
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resumen.map((jornada, i) => {
                        let verUrl = `/servicios/${jornada.tarea_id}`;
                        if (jornada.id) verUrl = `${verUrl}/partes-trabajo/${jornada.id}`;

                        const marcajesAbiertos =
                            jornada.marcajes &&
                            jornada.marcajes.some((m) => m.hora_entrada !== null && m.hora_salida === null);

                        return (
                            <TableRow key={i}>
                                <TableCell>{jornada.cliente}</TableCell>
                                <TableCell>{jornada.servicio}</TableCell>
                                <TableCell>
                                    {jornada.minutos !== null
                                        ? formatTiempo(createTiempo(jornada.minutos))
                                        : 'Sin registro'}
                                </TableCell>
                                <TableCell>
                                    {jornada.revisada ? (
                                        <Tooltip title={`Revisado el ${formatDateTime(jornada.fecha_revision)}`}>
                                            <LockIcon fontSize='small' />
                                        </Tooltip>
                                    ) : null}
                                </TableCell>
                                <TableCell>
                                    {jornada.id ? (
                                        <Tooltip title='Ver parte de trabajo'>
                                            <IconButton
                                                style={{ padding: 6 }}
                                                aria-label='Ver parte de trabajo'
                                                onClick={toggleDrawerJornada(jornada)}
                                            >
                                                <VisibilityIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title='Ver servicio'>
                                            <IconButton aria-label='Ver' to={verUrl} component={NavLink}>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {jornada.revisada === false && (
                                        <Tooltip title='Marcar como revisado'>
                                            <IconButton
                                                aria-label='Marcar como revisado'
                                                onClick={() =>
                                                    tareasProvider.marcarJornadaRevisada(jornada.id).then(fetchData)
                                                }
                                            >
                                                <DoneIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {jornada.marcajes && (
                                        <MarcajesDialog
                                            button={
                                                <Tooltip
                                                    arrow
                                                    title={marcajesAbiertos ? 'Hay marcajes abiertos' : 'Ver marcajes'}
                                                >
                                                    <IconButton>
                                                        <ScheduleIcon color={marcajesAbiertos ? 'error' : 'inherit'} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            jornada={{
                                                ...jornada,
                                                fecha: formatISODate(fecha),
                                                operario_id: id,
                                            }}
                                            tareaId={jornada.tarea_id}
                                            onSave={fetchData}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={2}>Total</TableCell>
                        <TableCell>{formatTiempo(tiempoTotal)}</TableCell>
                        <TableCell colSpan={2}></TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </DialogEditor>
    );
}

export default withButtonOpener(ResumenJornadaOperarioDialog);

ResumenJornadaOperarioDialog.propTypes = {
    fecha: PropTypes.any,
    id: PropTypes.any,
    open: PropTypes.any,
    operario: PropTypes.any,
};
