import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { albaranesCompraProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate } from '../../utils';

const Table = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: 'Table' },
)(MuiTable);

const TableCell = withStyles(
    (theme) => ({
        root: {
            background: 'transparent',
            color: 'white',
            padding: theme.spacing(0, 2),
            border: 'none',
            'th&': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    }),
    { name: 'TableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        tooltip: {
            backgroundColor: theme.palette.neutral.hudBackground,
            opacity: 1,
            padding: theme.spacing(1),
            minWidth: 460,
        },
        tooltipArrow: {
            color: theme.palette.neutral.hudBackground,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                color: theme.palette.neutral.primary,
                paddingLeft: theme.spacing(0.5),
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                fontSize: 20,
            },
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                flex: 1,
                marginLeft: theme.spacing(1),
            },
        },
        button: {
            color: 'white',
        },
        info: {
            paddingLeft: theme.spacing(3.5),
            marginTop: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTypography-root:first-of-type': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    }),
    { name: 'HistoricoPreciosTooltip' },
);

function HistoricoPrecios({ open, linea, onLoad }) {
    const fetchDataFn = useCallback(
        () =>
            albaranesCompraProvider.getAll(`revision-material/${linea.id}/precios`).then((lineas) => {
                onLoad(lineas);
                return lineas;
            }),
        [linea.id],
    );
    const { data: precios, fetchData } = useFetchData(fetchDataFn, linea.precios ?? []);

    useEffect(() => {
        if (!open || linea.precios !== undefined) return;

        fetchData();
    }, [fetchData, open]);

    return (
        <div>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Unidades</TableCell>
                        <TableCell>Precio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {precios === null || precios.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={3}>No hay precios</TableCell>
                        </TableRow>
                    ) : (
                        precios.map((precio, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{formatDate(precio.fecha_solicitud)}</TableCell>
                                <TableCell>{precio.unidades}</TableCell>
                                <TableCell>{precio.precio_unitario}</TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

HistoricoPrecios.propTypes = {
    linea: PropTypes.any,
    open: PropTypes.bool,
    onLoad: PropTypes.func,
};

export default function HistoricoPreciosTooltip({ linea, onLoad }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const closeTooltip = () => {
        setOpen(false);
    };

    const toggleTooltip = () => {
        setOpen((open) => !open);
    };

    return (
        <ClickAwayListener onClickAway={closeTooltip}>
            <div>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    arrow
                    interactive
                    onClose={closeTooltip}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<HistoricoPrecios linea={linea} open={open} onLoad={onLoad} />}
                    classes={{
                        tooltip: classes.tooltip,
                    }}
                >
                    <IconButton onClick={toggleTooltip} size='small'>
                        <InfoIcon size='small' />
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
}

HistoricoPreciosTooltip.propTypes = {
    linea: PropTypes.any,
    onLoad: PropTypes.func,
};
