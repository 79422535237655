import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import { ButtonDialog } from '../../dialogs/ButtonDialog';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '../../../icons/Download';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(1)}px`,
            '& .MuiTypography-root': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                flex: 1,
                maxWidth: 350,
            },
        },
    }),
    { name: 'AdjuntosItem' },
);

const useThumbStyles = makeStyles(
    (theme) => ({
        root: {
            marginTop: theme.spacing(0.5),
            cursor: 'pointer',
        },
    }),
    { name: 'Thumbnail' },
);

function Thumbnail({ thumbnail, url }) {
    const classes = useThumbStyles();
    return (
        <div className={classes.root} onClick={() => window.open(url, '_blank')}>
            <img src={thumbnail} />
        </div>
    );
}

export default function AdjuntosItem({ adjunto, onDelete }) {
    const classes = useStyles();
    const isImage = adjunto.type.startsWith('image');

    let uploadTaskId = null;
    let adjuntoName = '';

    if (!adjunto.existing || adjunto.path.startsWith('http')) {
        adjuntoName = adjunto.name ?? adjunto.path.split('/').pop();
    } else {
        const [taskId, fileName] = adjunto.path.split('#');
        adjuntoName = fileName;
        uploadTaskId = taskId;
    }

    let icon = null;

    if (uploadTaskId) {
        icon = <CircularProgress size={24} />;
    } else {
        icon = isImage ? <ImageIcon /> : <PictureAsPdfIcon />;
    }

    return (
        <Tooltip
            arrow
            interactive
            title={
                adjunto.existing && !uploadTaskId && isImage ? (
                    <Thumbnail thumbnail={adjunto.thumbnail} url={adjunto.image} />
                ) : (
                    ''
                )
            }
        >
            <div className={classes.root}>
                {icon}
                <Typography>
                    {uploadTaskId ? 'Subiendo ' : ''}
                    {adjuntoName}
                </Typography>
                {adjunto.existing && (
                    <IconButton size='small' onClick={() => window.open(adjunto.path, '_blank')}>
                        <DownloadIcon />
                    </IconButton>
                )}
                {adjunto.canDelete && (
                    <ButtonDialog
                        button={
                            <IconButton size='small' aria-label='delete'>
                                <DeleteIcon />
                            </IconButton>
                        }
                        dialogTitle='¿Borrar adjunto?'
                        dialogText='Esta opcion no se puede deshacer. ¿Deseas continuar?'
                        onConfirm={() => onDelete(adjunto)}
                    />
                )}
            </div>
        </Tooltip>
    );
}

AdjuntosItem.propTypes = {
    adjunto: PropTypes.any,
    onDelete: PropTypes.any,
};

Thumbnail.propTypes = {
    thumbnail: PropTypes.any,
    url: PropTypes.any,
};
