import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { presupuestosProvider } from '../../../api';
import { tipoUnidadesOptions } from '../../../api/presupuestos';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import SelectField from '../../common/fields/SelectField';
import DialogForm from '../../common/forms/DialogForm';
import PrecioFields from './PrecioFields';

const PartidaSchema = Yup.object().shape({
    referencia: Yup.string(),
    concepto: Yup.string().required('Requerido'),
    unidades: Yup.number()
        .required('Requerido')
        .test('gt-0', 'Debe ser mayor que 0', (value) => value > 0),
});

function EditPartidaDialog({ presupuestoId, linea, onSave, editable, ...props }) {
    return (
        <DialogForm
            title='Editar partida'
            maxWidth='sm'
            FormikProps={{
                initialValues: {
                    referencia: linea.referencia ?? '',
                    concepto: linea.concepto,
                    unidades: linea.unidades,
                    tipo_unidades: linea.tipo_unidades,
                    precio_coste: linea.precio_coste,
                    precio_venta: linea.precio_venta,
                    margen: (linea.margen * 100).toFixed(2),
                    descripcion: linea.descripcion,
                    observaciones: linea.observaciones,
                },
                validationSchema: PartidaSchema,
                onSubmit: ({ margen, ...values }, { setSubmitting, setFieldError }) => {
                    presupuestosProvider
                        .updateLinea(presupuestoId, linea.id, values)
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField name='referencia' label='Referencia' fullWidth disabled={!editable} />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField name='concepto' label='Concepto' fullWidth disabled={!editable} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField name='unidades' label='Unidades' fullWidth type='number' disabled={!editable} />
                        </Grid>
                        <Grid item xs={9}>
                            <Field
                                name='tipo_unidades'
                                id='tipo_unidades'
                                component={SelectField}
                                label='Tipo de unidades'
                                fullWidth
                                selectOptions={tipoUnidadesOptions}
                                disabled={!editable}
                            />
                        </Grid>
                        <PrecioFields
                            setFieldValue={setFieldValue}
                            values={values}
                            precioCosteDisabled={linea.lineas.length > 0}
                            disabled={!editable}
                        />
                        <Grid item xs={12}>
                            <TextField
                                name='descripcion'
                                label='Descripción'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                multiline
                                minRows={4}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        </DialogForm>
    );
}

EditPartidaDialog.propTypes = {
    presupuestoId: PropTypes.string,
    linea: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    editable: PropTypes.bool,
};

export default withButtonOpener(EditPartidaDialog);
