import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { formatDate } from '../../../utils';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEditFormData } from '../../common/forms/EditForm/EditFormContext';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteDialog from '../../common/dialogs/DeleteDialog';
import { operariosProvider } from '../../../api';

const DenseTable = withStyles(
    (theme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: 'DenseTable' },
)(MuiTable);

const DenseTableCell = withStyles(
    (theme) => ({
        root: {
            padding: theme.spacing(0, 2),
        },
    }),
    { name: 'DenseTableCell' },
)(MuiTableCell);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium,
            borderBottom: `1px solid ${theme.palette.divider}`,
            lineHeight: '50px',
            paddingLeft: theme.spacing(2),
        },
        actions: {
            whiteSpace: 'nowrap',
            textAlign: 'right',
            border: 'none',
            '& :not(.MuiSwitch-switchBase).MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root svg': {
                fontSize: 20,
            },
        },
    }),
    { name: 'HistoricoHorarios' },
);

export function HistoricoHorarios() {
    const classes = useStyles();

    const { data: operario, fetchData } = useEditFormData();
    const horarios = operario.horarios;

    return (
        <Paper elevation={0} className={classes.root}>
            <Typography variant='body2' className={classes.title}>
                Historial de cambios de horario
            </Typography>
            <DenseTable size='small' style={{ marginTop: 8 }}>
                <TableHead>
                    <TableRow>
                        <DenseTableCell style={{ width: '50%' }}>Horario</DenseTableCell>
                        <DenseTableCell style={{ width: '50%' }}>En vigor desde</DenseTableCell>
                        <DenseTableCell></DenseTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {horarios.map((horario, i) => (
                        <TableRow key={i}>
                            <DenseTableCell style={{ whiteSpace: 'pre' }}>{horario.descripcion}</DenseTableCell>
                            <DenseTableCell>
                                {horario.fecha ? formatDate(horario.fecha) : 'Horario inicial'}
                            </DenseTableCell>
                            <DenseTableCell className={classes.actions}>
                                <DeleteDialog
                                    text='¿Estás seguro que quieres eliminar este horario?'
                                    onConfirm={() => {
                                        operariosProvider.deleteHorarios(operario.id, i).then(fetchData);
                                    }}
                                    button={
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                />
                            </DenseTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </DenseTable>
        </Paper>
    );
}
