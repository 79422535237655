import MuiCheckbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { albaranesCompraProvider } from '../../api';
import Button from '../common/Button';
import { ButtonDialog } from '../common/dialogs/ButtonDialog';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { useEditFormData } from '../common/forms/EditForm/EditFormContext';
import MaterialEditor from '../servicios/ParteTrabajoList/MaterialEditor';
import ImputacionInfoTooltip from './ImputacionInfoTooltip';
import ImputarMaterialesDialog from './ImputarMaterialesDialog';

const useStyles = makeStyles(
    (theme) => ({
        rowActions: {
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            minHeight: 32,
            '& .MuiIconButton-root': {
                padding: 6,
            },
            '& .MuiIconButton-root .MuiSvgIcon-root': {
                fontSize: '1.25rem',
            },
        },
        title: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            minHeight: 50,
            paddingLeft: 24,
            paddingRight: 24,
            borderBottom: '1px solid #eeeeee',
            gap: `${theme.spacing(1)}px`,
        },
        selectCell: {
            width: 48,
        },
    }),
    { name: 'AlbaranCompraLineasList' },
);

const Checkbox = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5),
    },
}))(MuiCheckbox);

const TableCell = withStyles((theme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.neutral.grey2}`,
    },
}))(MuiTableCell);

export default function AlbaranCompraLineasList({ albaranCompraId }) {
    const classes = useStyles();
    const { data: preparacionMaterial } = useEditFormData('preparacion_material');
    const { data, fetchData } = useEditFormData('lineas');
    const history = useHistory();

    const [selectedRowIds, setSelectedRowIds] = React.useState([]);

    const [editMaterialInfo, setEditMaterialInfo] = React.useState(null);
    const openMaterialEditor =
        (albaranCompraId, { id, ...material } = { id: null, descripcion: '', unidades: '' }) =>
        (event) => {
            setEditMaterialInfo({
                albaranCompraId,
                lineaId: id,
                material,
            });
        };
    const closeMaterialEditor = () => {
        setEditMaterialInfo(null);
    };

    function onChangeSelectAll(ev) {
        if (ev.target.checked) {
            setSelectedRowIds(data.filter((row) => !row.imputacion).map((row) => row.id));
        } else {
            setSelectedRowIds([]);
        }
    }

    function onSelectRow(id) {
        setSelectedRowIds((selectedRows) => {
            if (selectedRows.includes(id)) {
                return selectedRows.filter((rowId) => rowId !== id);
            }
            return [...selectedRows, id];
        });
    }

    const allChecked = data.length > 0 && selectedRowIds.length === data.length;
    const someChecked = selectedRowIds.length > 0 && selectedRowIds.length < data.length;

    const importeTotal = data.reduce((total, row) => total + row.importe, 0);
    const selectedRows = useMemo(() => data.filter((row) => selectedRowIds.includes(row.id)), [data, selectedRowIds]);
    const allImputadas = useMemo(() => data.every((row) => row.imputacion || row.verificada), [data]);

    const hasPreparacionMaterial = preparacionMaterial !== null;

    return (
        <>
            <MaterialEditor
                open={Boolean(editMaterialInfo)}
                {...editMaterialInfo}
                onClose={closeMaterialEditor}
                onSave={(material) => {
                    const { albaranCompraId, lineaId } = editMaterialInfo;
                    return albaranesCompraProvider.updateLinea(albaranCompraId, lineaId, material).then(() => {
                        fetchData();
                        closeMaterialEditor();
                    });
                }}
                showCoste={false}
            />
            {hasPreparacionMaterial && (
                <Alert
                    variant='filled'
                    severity='info'
                    style={{ marginBottom: 24 }}
                    action={
                        <Button
                            color='inherit'
                            onClick={() =>
                                history.push(
                                    `/servicios/${preparacionMaterial.id}/preparaciones-material/${preparacionMaterial.preparacion_material_id}`,
                                )
                            }
                        >
                            Ver preparación de material
                        </Button>
                    }
                >
                    Este albarán de compra esta relacionado con una preparación de material del servicio{' '}
                    <strong style={{ marginLeft: 8 }}>
                        {preparacionMaterial.numero} | {preparacionMaterial.cliente} | {preparacionMaterial.descripcion}
                    </strong>
                </Alert>
            )}
            <Paper elevation={0}>
                <div className={classes.title}>
                    <Typography variant='h6' style={{ flex: 1 }}>
                        Lista de materiales
                    </Typography>

                    {hasPreparacionMaterial ? (
                        <ButtonDialog
                            button={
                                <Button color='dark' disabled={selectedRowIds.length === 0}>
                                    Verificar seleccionados
                                </Button>
                            }
                            dialogTitle='¿Verificar seleccionados?'
                            dialogText='Se aumentarán las unidades de la preparación de materiales vinculada'
                            onConfirm={() => {
                                albaranesCompraProvider.verificarLineas(albaranCompraId, selectedRowIds).then(() => {
                                    fetchData();
                                    setSelectedRowIds([]);
                                });
                            }}
                        />
                    ) : (
                        <ImputarMaterialesDialog
                            albaranCompraId={albaranCompraId}
                            selectedRows={selectedRows}
                            button={
                                <Button color='dark' disabled={selectedRowIds.length === 0}>
                                    Imputar seleccionados a servicio
                                </Button>
                            }
                            onSave={() => {
                                fetchData();
                                setSelectedRowIds([]);
                            }}
                        />
                    )}
                    <Button color='info' startIcon={<AddIcon />} onClick={openMaterialEditor(albaranCompraId)}>
                        Añadir material
                    </Button>
                </div>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {!allImputadas && (
                                <TableCell className={classes.selectCell}>
                                    <Checkbox
                                        checked={allChecked}
                                        indeterminate={someChecked}
                                        onChange={onChangeSelectAll}
                                    />
                                </TableCell>
                            )}
                            <TableCell>Descripción</TableCell>
                            <TableCell align='right'>Unidades</TableCell>
                            <TableCell align='right'>Precio</TableCell>
                            <TableCell align='right'>Descuento</TableCell>
                            <TableCell align='right'>Importe</TableCell>
                            {!hasPreparacionMaterial && <TableCell>Imputación</TableCell>}
                            {hasPreparacionMaterial && <TableCell>Verificada</TableCell>}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {!allImputadas && (
                                    <TableCell>
                                        {!row.imputacion && !row.verificada && (
                                            <Checkbox
                                                checked={selectedRowIds.includes(row.id)}
                                                onChange={() => onSelectRow(row.id)}
                                            />
                                        )}
                                    </TableCell>
                                )}
                                <TableCell>{row.descripcion}</TableCell>
                                <TableCell align='right'>{row.unidades}</TableCell>
                                <TableCell align='right'>{row.precio_unitario}</TableCell>
                                <TableCell align='right'>{row.descuento}</TableCell>
                                <TableCell align='right'>{row.importe.toFixed(2)} EUR</TableCell>
                                {!hasPreparacionMaterial && (
                                    <TableCell>
                                        <ImputacionInfoTooltip imputacion={row.imputacion} />
                                    </TableCell>
                                )}
                                {hasPreparacionMaterial && <TableCell>{row.verificada ? 'Si' : 'No'}</TableCell>}
                                <TableCell>
                                    <div className={classes.rowActions}>
                                        <IconButton onClick={openMaterialEditor(albaranCompraId, row)}>
                                            <EditIcon />
                                        </IconButton>
                                        <DeleteDialog
                                            text='¿Deseas borrar esta línea?'
                                            button={
                                                <Tooltip title='Borrar linea'>
                                                    <IconButton aria-label='Borrar linea'>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            deleteButtonText='Quitar'
                                            onConfirm={() => {
                                                albaranesCompraProvider
                                                    .deleteLinea(albaranCompraId, row.id)
                                                    .then(fetchData);
                                            }}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={allImputadas ? 4 : 5}></TableCell>
                            <TableCell align='right'>{importeTotal.toFixed(2)} EUR</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </>
    );
}

AlbaranCompraLineasList.propTypes = {
    albaranCompraId: PropTypes.any,
    data: PropTypes.any,
};
