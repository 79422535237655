import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { proyectosProvider } from '../../../api';
import { tipoUnidadesShortLabels } from '../../../api/presupuestos';
import { withButtonOpener } from '../../../hooks/withButtonOpener';
import DialogForm from '../../common/forms/DialogForm';

const AvanceSchema = Yup.object().shape({
    avance: Yup.object().shape({
        unidades: Yup.number().required('Campo requerido'),
        porcentaje: Yup.number().required('Campo requerido'),
        total_coste: Yup.number().required('Campo requerido'),
        total_venta: Yup.number().required('Campo requerido'),
    }),
});

const useStyles = makeStyles(
    (theme) => ({
        dialogContent: {
            padding: 0,
        },
        content: {
            padding: theme.spacing(2),
        },
        subheader: {
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.neutral.grey3,
        },
    }),
    { name: 'EditAvanceDialog' },
);

function EditAvanceDialog({ avanceId, linea, onSave, ...props }) {
    const classes = useStyles();

    if (!props.open) return null;

    return (
        <DialogForm
            title='Editar avance de obra'
            maxWidth='sm'
            classes={{
                content: classes.dialogContent,
            }}
            FormikProps={{
                initialValues: {
                    referencia: linea.referencia ?? '',
                    concepto: linea.concepto,
                    unidades: linea.unidades,
                    avance: linea.avance,
                    tipo_unidades: linea.tipo_unidades,
                    total_coste: linea.total_coste,
                    total_venta: linea.total_venta,
                    margen: linea.margen * 100,
                    descripcion: linea.descripcion,
                },
                validationSchema: AvanceSchema,
                onSubmit: ({ avance, ...values }, { setSubmitting, setFieldError }) => {
                    proyectosProvider
                        .updateAvanceLinea(avanceId, { ...avance, id: linea.avance.id })
                        .then((res) => {
                            props.onClose();
                            onSave(res);
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                for (const [field, errors] of Object.entries(err.message)) {
                                    setFieldError(field, errors.join('\n'));
                                }
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                },
            }}
            {...props}
        >
            {({ values, setFieldValue }) => {
                return (
                    <>
                        <Typography className={classes.subheader}>Línea de presupuesto</Typography>
                        <div className={classes.content}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField name='referencia' label='Referencia' fullWidth disabled />
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField name='concepto' label='Concepto' fullWidth disabled />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='descripcion'
                                        label='Descripción'
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='unidades'
                                        label='Unidades presup.'
                                        fullWidth
                                        type='number'
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {tipoUnidadesShortLabels[values.tipo_unidades]}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='total_coste'
                                        label='Total coste'
                                        fullWidth
                                        type='number'
                                        disabled
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='total_venta'
                                        label='Total venta'
                                        fullWidth
                                        type='number'
                                        disabled
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <Typography className={classes.subheader}>Avance de obra</Typography>
                        <div className={classes.content}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        name='avance.unidades'
                                        label='Unidades avance'
                                        fullWidth
                                        type='number'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {tipoUnidadesShortLabels[values.tipo_unidades]}
                                                </InputAdornment>
                                            ),
                                            onBlur: (ev) => {
                                                const unidadesPresupuesto = values.unidades;
                                                const unidadesAvance = ev.target.value;
                                                const porcentaje = unidadesAvance / unidadesPresupuesto;
                                                const totalCoste = values.total_coste * porcentaje;
                                                const totalVenta = values.total_venta * porcentaje;

                                                setFieldValue(
                                                    'avance.porcentaje',
                                                    Math.round(porcentaje * 10000) / 100,
                                                );
                                                setFieldValue('avance.total_coste', Math.round(totalCoste * 100) / 100);
                                                setFieldValue('avance.total_venta', Math.round(totalVenta * 100) / 100);
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='avance.porcentaje'
                                        label='% avance'
                                        fullWidth
                                        type='number'
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                                            onBlur: (ev) => {
                                                const porcentaje = ev.target.value / 100;

                                                const unidadesAvance = values.unidades * porcentaje;
                                                const totalCoste = values.total_coste * porcentaje;
                                                const totalVenta = values.total_venta * porcentaje;

                                                setFieldValue(
                                                    'avance.unidades',
                                                    Math.round(unidadesAvance * 100) / 100,
                                                );
                                                setFieldValue('avance.total_coste', Math.round(totalCoste * 100) / 100);
                                                setFieldValue('avance.total_venta', Math.round(totalVenta * 100) / 100);
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='avance.total_coste'
                                        label='Total coste avance'
                                        fullWidth
                                        type='number'
                                        disabled
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name='avance.total_venta'
                                        label='Total venta avance'
                                        fullWidth
                                        type='number'
                                        disabled
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name='avance.observaciones'
                                        label='Observaciones'
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </>
                );
            }}
        </DialogForm>
    );
}

EditAvanceDialog.propTypes = {
    avanceId: PropTypes.string,
    linea: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

export default withButtonOpener(EditAvanceDialog);
