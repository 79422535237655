import * as PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { preparacionesMaterialProvider, tareasProvider } from '../../api';
import { useFetchData } from '../../hooks/useFetchData';
import EditForm from '../common/forms/EditForm/EditForm';
import { EditServicioFormContent } from './EditServicioFormContent';

export const JORNADAS_ROUTE = 'partes-trabajo';
export const ALBARANES_ROUTE = 'albaranes-servicio';
export const PARTES_MANTENIMIENTO_ROUTE = 'revisiones-equipos';
export const PREPARACIONES_MATERIAL_ROUTE = 'preparaciones-material';
export const RENDIMIENTO_ROUTE = 'rendimiento';
export const CHECKLISTS_ROUTE = 'tareas';

export default function EditServicioForm() {
    const { id } = useParams();
    const { isExact: isRouteExact, url } = useRouteMatch();
    const history = useHistory();

    const fetchDataFn = useCallback(
        () =>
            Promise.all([tareasProvider.getAll(id), preparacionesMaterialProvider.getByTarea(id)]).then(
                ([tarea, preparacionesMaterial]) => {
                    tarea.preparaciones_material = preparacionesMaterial;
                    // tarea.checklists_contar_tiempo = tarea.checklists_tarea.filter(
                    //     (checklist) => checklist.contar_tiempo,
                    // );
                    return tarea;
                },
            ),
        [id],
    );
    const { data, fetchData } = useFetchData(fetchDataFn, null);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (!isRouteExact || !data) return;

        history.replace(`${url}/${JORNADAS_ROUTE}`);
    }, [data, url, isRouteExact]);

    return (
        data && (
            <>
                <EditForm data={data} fetchData={fetchData}>
                    <EditServicioFormContent />
                </EditForm>
            </>
        )
    );
}

EditServicioForm.propTypes = {
    basePath: PropTypes.any,
};
